/*
 * Copyright 2022 DevIntent - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without the express permission of DevIntent.
 *
 * No warranty, explicit or implicit, provided.
 * In no event shall DevIntent be liable for any claim or damages related to this code.
 */
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatDrawer, MatSidenavContent } from '@angular/material/sidenav';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AnalyticsService } from '../analytics.service';
import { ThemeService } from '../core/services/theme.service';
import { NavService } from '../nav.service';
import { Theme } from '../shared/theme-toggle/theme';

@Component({
  selector: 'dev-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements AfterViewInit {
  @ViewChild('drawer', { static: true }) drawer: MatDrawer;
  @ViewChild('sidenavContent', { static: true }) sidenavContent: MatSidenavContent;
  theme = Theme;

  constructor(
    public navService: NavService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    public readonly analyticsService: AnalyticsService,
    private readonly router: Router,
    public themeService: ThemeService,
  ) {
    iconRegistry.addSvgIcon('github', sanitizer.bypassSecurityTrustResourceUrl('assets/font-awesome/svgs/github.svg'));
    iconRegistry.addSvgIcon('twitter', sanitizer.bypassSecurityTrustResourceUrl('assets/font-awesome/svgs/twitter.svg'));
    iconRegistry.addSvgIcon('youtube', sanitizer.bypassSecurityTrustResourceUrl('assets/font-awesome/svgs/youtube.svg'));
    iconRegistry.addSvgIcon('dev', sanitizer.bypassSecurityTrustResourceUrl('assets/font-awesome/svgs/dev.svg'));
    iconRegistry.addSvgIcon('linkedin', sanitizer.bypassSecurityTrustResourceUrl('assets/font-awesome/svgs/linkedin.svg'));
    iconRegistry.addSvgIcon('medium', sanitizer.bypassSecurityTrustResourceUrl('assets/font-awesome/svgs/medium-m.svg'));
    iconRegistry.addSvgIcon('facebook', sanitizer.bypassSecurityTrustResourceUrl('assets/font-awesome/svgs/facebook-f.svg'));
    iconRegistry.addSvgIcon('npm', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/npm-logo.svg'));
  }

  get route(): string {
    return this.router.url;
  }

  ngAfterViewInit(): void {
    this.navService.drawer = this.drawer;
    this.navService.sidenavContent = this.sidenavContent;
  }

  onNavigation(): void {
    // Check display size so that we don't close a locked open sidenav on larger displays
    this.navService.isSmallOrLess.pipe(take(1)).subscribe((isSmallOrLess: boolean) => {
      // Need to close an 'over' mode sidenav, used on mobile.
      if (isSmallOrLess) {
        this.navService.drawer.close();
      }
    });
  }
}
