/*
 * Copyright 2022 DevIntent - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without the express permission of DevIntent.
 *
 * No warranty, explicit or implicit, provided.
 * In no event shall DevIntent be liable for any claim or damages related to this code.
 */
import { LayoutModule } from '@angular/cdk/layout';
import { NgOptimizedImage } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import {
  AngularFireAnalyticsModule,
  APP_NAME,
  APP_VERSION,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/compat/analytics';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirePerformanceModule, PerformanceMonitoringService } from '@angular/fire/compat/performance';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AboutComponent } from './about/about.component';
import { appRoutes } from './app-routes';
import { AppComponent } from './app.component';
import { BlogPostCardsComponent } from './blog-post-cards/blog-post-cards.component';
import { FooterComponent } from './footer/footer.component';
import { NavComponent } from './nav/nav.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { BlogPostCardModule } from './shared/blog-post-card/blog-post-card.module';
import { ContactUsButtonModule } from './shared/contact-us-button/contact-us-button.module';
import { ThemeToggleModule } from './shared/theme-toggle/theme-toggle.module';
import { TechnologyCardsComponent } from './technology-cards/technology-cards.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    PageNotFoundComponent,
    NavComponent,
    FooterComponent,
    BlogPostCardsComponent,
    TechnologyCardsComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, {
      useHash: false,
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'disabled',
      anchorScrolling: 'disabled',
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    AngularFirePerformanceModule,
    // According to the [SW update algorithm](https://w3c.github.io/ServiceWorker/#update-algorithm)
    // from the spec, browsers do a byte-for-byte comparison of the SW script response's body (i.e.
    // ignore headers). In order to be able to pick up updated headers (for example,
    // as part of a CSP update), we need to change the requested SW script URL
    // (via a query param).
    ServiceWorkerModule.register('ngsw-worker.js?v3', {
      enabled: environment.serviceWorkerEnabled,
      registrationStrategy: 'registerWithDelay:5000',
    }),
    LayoutModule,
    MatToolbarModule,
    MatTabsModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatListModule,
    MatDividerModule,
    MatCardModule,
    BlogPostCardModule,
    NgOptimizedImage,
    ContactUsButtonModule,
    ThemeToggleModule,
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    PerformanceMonitoringService,
    { provide: APP_NAME, useValue: environment.firebase.projectId },
    { provide: APP_VERSION, useValue: environment.version },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
