import { Component } from '@angular/core';
import { ScullyRoute, ScullyRoutesService } from '@scullyio/ng-lib';
import { map, Observable } from 'rxjs';
import { NavService } from '../nav.service';

@Component({
  selector: 'dev-blog-post-cards',
  templateUrl: './blog-post-cards.component.html',
  styleUrls: ['./blog-post-cards.component.scss'],
})
export class BlogPostCardsComponent {
  blogPosts: Observable<ScullyRoute[]>;
  private blogPostsLimit = 3;
  constructor(private scully: ScullyRoutesService, private navService: NavService) {
    // Return recent articles
    this.blogPosts = this.scully.available$.pipe(
      map((routeList: ScullyRoute[]) => {
        const blogPosts = routeList.filter((scullyRoute: ScullyRoute) => scullyRoute.route.startsWith('/blog/'));
        const latestBlogPosts = [...blogPosts].reverse().slice(0, this.blogPostsLimit);
        return [...latestBlogPosts];
      }),
    );
  }
}
