import { Component } from '@angular/core';
import { Technology } from './technology';

@Component({
  selector: 'dev-technology-cards',
  templateUrl: './technology-cards.component.html',
  styleUrls: ['./technology-cards.component.scss'],
})
export class TechnologyCardsComponent {
  public technologies: Technology[] = [
    {
      name: 'Angular',
      description: 'Our expertise will help you build your app faster.',
      icon: 'angular',
    },
    {
      name: 'AngularJS',
      description: 'Ensure the longevity of your legacy web applications.',
      icon: 'angularjs',
    },
    {
      name: 'React',
      description: 'We’ll help you build and maintain effortless UIs.',
      icon: 'react',
    },
    {
      name: 'Material Design',
      description: 'Create user experiences backed by years of research.',
      icon: 'material-design-icon',
    },
  ];
}
