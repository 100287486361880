/*
 * Copyright 2022 DevIntent - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without the express permission of DevIntent.
 *
 * No warranty, explicit or implicit, provided.
 * In no event shall DevIntent be liable for any claim or damages related to this code.
 */
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat';
import LogRocket from 'logrocket';
import User = firebase.User;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private auth: AngularFireAuth) {
    this.auth.authState.subscribe((authState: User | null) => {
      if (!authState) {
        this.auth.signInAnonymously().catch(error => console.error(error));
      } else {
        let traits: { [propName: string]: string | number | boolean } = {
          // Add your own custom user variables here
          isAnonymous: authState.isAnonymous,
        };
        if (authState.metadata && authState.metadata.creationTime) {
          traits.creationTime = authState.metadata.creationTime;
        }
        if (authState.metadata && authState.metadata.lastSignInTime) {
          traits.lastSignInTime = authState.metadata.lastSignInTime;
        }
        if (authState.displayName) {
          traits.name = authState.displayName;
        }
        if (authState.email) {
          traits.email = authState.email;
        }
        LogRocket.identify(authState.uid, traits);
      }
    });
  }
}
