<section class="blog-posts">
  <div class="blog-posts-container">
    <ng-container *ngFor="let post of blogPosts | async">
      <dev-blog-post-card [post]="post">
        <span subTitle>
          {{ post.updatedAt ?? post.publishedAt | date : 'longDate' }}
        </span>
        <a actions mat-button [routerLink]="post.route"> Read more <mat-icon iconPositionEnd>keyboard_arrow_right</mat-icon></a>
      </dev-blog-post-card>
    </ng-container>
  </div>
</section>
