/*
 * Copyright 2020 DevIntent - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without the express permission of DevIntent.
 *
 * No warranty, explicit or implicit, provided.
 * In no event shall DevIntent be liable for any claim or damages related to this code.
 */
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IdleMonitorService } from '@scullyio/ng-lib';
import { environment } from '../environments/environment';
import { AuthService } from './auth.service';
import { NavService } from './nav.service';
import { SwUpdateService } from './sw-update.service';

@Component({
  selector: 'dev-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private idle: IdleMonitorService,
    private router: Router,
    private swUpdateService: SwUpdateService,
    private authService: AuthService,
    private navService: NavService,
  ) {
    if (environment.serviceWorkerEnabled) {
      this.swUpdateService.checkForUpdate();
    }
  }
}
