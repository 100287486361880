import { Component } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

import { ThemeService } from 'src/app/core/services/theme.service';

import { Theme } from './theme';

@Component({
  selector: 'dev-theme-toggle',
  templateUrl: './theme-toggle.component.html',
  styleUrls: ['./theme-toggle.component.scss'],
})
export class ThemeToggleComponent {
  theme = Theme;
  selectedTheme = Theme.AUTO;

  constructor(private themeService: ThemeService) {
    const storedThemePreference = this.themeService.getStoredTheme();
    if (storedThemePreference) {
      this.selectedTheme = storedThemePreference;
    }
  }

  toggleTheme(event: MatButtonToggleChange) {
    this.selectedTheme = event.value;
    this.themeService.setTheme(this.selectedTheme);
  }
}
