/*
 * Copyright 2020 DevIntent - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without the express permission of DevIntent.
 *
 * No warranty, explicit or implicit, provided.
 * In no event shall DevIntent be liable for any claim or damages related to this code.
 */
import { Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

export const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: AboutComponent,
    title: 'DevIntent',
  },
  {
    path: 'angularjs-services',
    loadChildren: () => import('./angularjs/angularjs.module').then(m => m.AngularjsModule),
    title: 'AngularJS Services',
  },
  {
    path: 'blog',
    loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule),
    title: 'DevIntent Blog',
  },
  {
    path: 'team',
    loadChildren: () => import('./team/team.module').then(m => m.TeamModule),
    title: 'DevIntent Team',
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./contact-form/contact-form.module').then(m => m.ContactFormModule),
    title: 'Contact Us - DevIntent',
  },
  {
    path: 'careers',
    loadChildren: () => import('./careers/careers.module').then(m => m.CareersModule),
    title: 'Careers - DevIntent',
  },
  { path: '404', component: PageNotFoundComponent, title: 'Page not found' },
  { path: '**', component: PageNotFoundComponent, title: 'Page not found' },
];
